import $ from 'jquery';
import { removeBodyHidden, addBodyHidden } from './bodyHiddenPosition';
import { BODY, ISHIDDEN } from '../_constants';

export function multipleForm() {

  const PLAN_FORM_HIDDEN = $('.js-plan-form');
  const CARD_BTN = $('.js-plan-btn');

  CARD_BTN.on('click', function() {
    getCardInfo(this);
  });
  
  
  function getCardInfo(el) {
    const PLAN = $(el).closest('.js-plan');

    const PLAN_NAME = PLAN.find('.js-plan-name').text();
    PLAN_FORM_HIDDEN.find('#description').attr('value', PLAN_NAME); //into json
    
    let cost = PLAN.attr('data-cost');
    if(cost) {
      PLAN_FORM_HIDDEN.find('#amount').attr('value', cost); //into json
      PLAN_FORM_HIDDEN.find('#variant').attr('value', ''); //into json
    } else {
      PLAN_FORM_HIDDEN.find('#amount').attr('value', '');
      PLAN_FORM_HIDDEN.find('#variant').attr('value', 'Позвоните мне! ' + PLAN_NAME); //into json
    }
    
    let popupTargetNext = PLAN.attr('data-target-popup');
    let nextBtn = $('.js-popup-form-info');
    nextBtn.attr('data-popup', popupTargetNext);
  }


  const PLAN_CARD = $('.js-plan');
  const HASH = window.location.hash;
  if(HASH) {
    PLAN_CARD.each(function() {
      let cardHash = $(this).attr('data-hash');
      if(HASH === cardHash) {
        let target = $(`.js-plan[data-hash="${cardHash}"]`).find('.js-plan-btn');
        getCardInfo(target);
        $('.js-popup[data-popup="plan-sign"]').fadeIn(300);
        addBodyHidden();  
      }
    });
  }



  const FORM_BTN = $('.js-form-valid-btn');
  
  FORM_BTN.each(function() {
    $(this).on('click', function(e) {
      e.preventDefault();
      const FORM = $(this).closest('.js-form-validate');
      let email = FORM.find('.js-validate-email');
      let emailValue = email.val();
      let validEmail = validateEmail(emailValue);
      if(validEmail === false) {
        email.addClass('is-error');
      } else {
        email.removeClass('is-error');
      }


      let phoneInput = FORM.find('.js-validate-phone');
      let maxPhoneLength = parseInt(phoneInput.attr('max-length-number'));
      let phoneValue = phoneInput.val();
      let validPhone = phoneValue.replace(/\D+/g, '').length >= maxPhoneLength;  
      if(validPhone === false) {
        phoneInput.addClass('is-error');
      } else {
        phoneInput.removeClass('is-error');
      }   
      
      

      let textInput = FORM.find('.js-plan-user');
      let userName = textInput.val();
      if(userName === '') {
        textInput.addClass('is-error');
      } else {
        textInput.removeClass('is-error');
      }


      if(validEmail !== false && validPhone !== false && userName !== '') {


        PLAN_FORM_HIDDEN.find('#user_name').attr('value', userName); //into json
        PLAN_FORM_HIDDEN.find('#user_phone').attr('value', phoneValue); //into json
        PLAN_FORM_HIDDEN.find('#user_email').attr('value', emailValue); //into json
        PLAN_FORM_HIDDEN.find('#type').attr('value', 'message'); //into json
        
        if($(this).attr('data-popup') === 'popup_thanks_msg') {
          PLAN_FORM_HIDDEN.find('#amount').attr('value', '1000000'); //into json
          $.ajax( {
            url: PLAN_FORM_HIDDEN.attr('action'),
            type: 'POST',
            dataType: 'html',
            data: PLAN_FORM_HIDDEN.serialize(),
            success: function(result) {
              console.log(result);
              liqPay(result);
            },
            error: function(xhr, resp, text) {        
              console.log('error');
              console.log(xhr, resp, text);
            }
          });
          
        }

    
        let $this = $(this),
          attr = $this.attr('data-popup');
        let target = $(`.js-popup[data-popup="${attr}"]`);
        
        let isOpen = $('.js-popup').is(':visible');
    
        $('.js-popup').fadeOut(200);
        target.fadeIn(300);
        if(!isOpen) {
          addBodyHidden();  
        }
          
        if(target.attr('data-popup') === 'popup_thanks_msg') {
          setTimeout(() => {
            BODY.removeClass(ISHIDDEN);
            removeBodyHidden();
            target.fadeOut(300);
          }, 2000);
        }
          

      }
      
    });
  });
  
  

  const SUBMIT_BTN = $('.js-radio-cost-btn');

  SUBMIT_BTN.on('click', function() {
    const RADIO_WRAPPER = $(this).closest('.js-radio-cost');
    const SELECTED = RADIO_WRAPPER.find('input[type=radio]:checked');
    const SELECTED_TEXT = SELECTED.siblings('label').text();    
    
    PLAN_FORM_HIDDEN.find('#variant').attr('value', SELECTED_TEXT); //into json

    var cost = PLAN_FORM_HIDDEN.find('#amount').val();
    var type;

    if(SELECTED.is('#prepaid')) {
      cost = '2800';
      type = 'pay';
    } else if (SELECTED.is('#discount_price')) {
      cost = parseInt(cost) - (parseInt(cost)*0.1);  
      type = 'pay';    
    } else if (SELECTED.is('#law')) {
      cost = '1000000';
      type = 'message';
    }

    if(type === 'message') {
      $('.js-popup').fadeOut(200);
      $('.js-popup[data-popup="popup_thanks_msg"]').fadeIn(300);
      setTimeout(() => {
        BODY.removeClass(ISHIDDEN);
        removeBodyHidden();
        $('.js-popup').fadeOut(300);
      }, 2000);
    } else {
      BODY.removeClass(ISHIDDEN);
      removeBodyHidden();
      $('.js-popup').fadeOut(200);
    }

    PLAN_FORM_HIDDEN.find('#amount').attr('value', cost); //into json
    
    PLAN_FORM_HIDDEN.find('#type').attr('value', type); //into json

    $.ajax( {
      url: PLAN_FORM_HIDDEN.attr('action'),
      type: 'POST',
      dataType: 'html',
      data: PLAN_FORM_HIDDEN.serialize(),
      success: function(result) {
        console.log(result);
        liqPay(result);
      },
      error: function(xhr, resp, text) {        
        console.log('error');
        console.log(xhr, resp, text);
        
      }
    } );
    
  });
 

  function liqPay(el) {

    let liqPayFrom = el;
    var form = document.createElement('div');
    $(form).addClass('form-hidden js-liqpay');
    document.body.appendChild( form );
    $(form).html(liqPayFrom);
    $(form).find('form').submit();
  }

  const validateEmail = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    
    return expression.test(String(email).toLowerCase());
  };
}
