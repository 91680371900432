import $ from 'jquery';
import sayHello from './lib/sayHello.js';
import { progressbar } from './components/progressBar.js';
import { fixedHeaderOnScroll } from './components/fixedHeaderOnScroll.js';
import { scrollAnchors } from './components/scrollAnchors.js';
import { popups } from './components/popup.js';
import { exit } from './components/exitPopup.js';
import { emailValidation } from './components/emailValidation.js';
import { phoneValidation } from './components/phoneValidation.js';
import { calendars } from './components/calendars.js';
import { viewportHeight } from './components/viewportHeight.js';
import { multipleForm } from './components/_multipleForm.js';
import { lazyLoad } from './components/lazyLoad.js';


sayHello();
lazyLoad();

$(document).ready(() => {

  progressbar();
  fixedHeaderOnScroll();
  scrollAnchors();
  popups();
  exit();
  emailValidation();
  phoneValidation();
  calendars();
  viewportHeight();
  multipleForm();
  

});
