import $ from 'jquery';

export function progressbar() {
  $(window).on('scroll', function() {
    const PROGRESS_BAR = $('.js-progress-bar');

    let windowScrollTop = $(window).scrollTop();
    let docHeight = $(document).height();
    let windowHeight = $(window).height();
    let progress = (windowScrollTop / (docHeight - windowHeight)) * 100;
    PROGRESS_BAR.width(progress + '%');
  });
}

