import $ from 'jquery';
import { getElemHeight } from './getElemSize.js';

export function scrollAnchors() {
  const ANCHOR_LINKS = $('.js-menu-link');

  ANCHOR_LINKS.on('click', function() {
    let anchor = $(this);
    let scrollDest = $(anchor.attr('href'));
    if (scrollDest) {
      $('html, body').stop().animate({
        scrollTop: scrollDest.offset().top - getElemHeight('.js-header') + 'px'
      }, 1000);
    }
  });
}
