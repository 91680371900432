import $ from 'jquery';
import { addBodyHidden } from './bodyHiddenPosition.js';

export function exit() {
// Exit intent
  function addEvent(obj, evt, fn) {
    if (obj.addEventListener) {
      obj.addEventListener(evt, fn, false);
    } else if (obj.attachEvent) {
      obj.attachEvent('on' + evt, fn);
    }
  }
  addEvent(window, 'load', function() {
    var valueofstore= JSON.parse(localStorage.getItem('exitintent_show'));
    if (valueofstore) {
      localStorage.removeItem('exitintent_show');
    }
  });

  
  // Exit intent trigger
  addEvent(document, 'mouseout', function(evt) {
      
    if (evt.toElement === null && evt.relatedTarget === null && !localStorage.getItem('exitintent_show') && !$('.js-popup').is(':visible')) {
      
      let target = $('.js-exit-popup');

      if(target.length) {
        $('.js-popup').fadeOut(200);
        target.fadeIn(300);
  
        addBodyHidden(); 
        localStorage.setItem('exitintent_show', 'true'); // Set the flag in localStorage
      }
      
      
      
    }
  });
};
