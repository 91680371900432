import $ from 'jquery';
import Inputmask from 'inputmask';

export function phoneValidation() {
  const PHONE_INPUT = $('.js-input-phone');

  PHONE_INPUT.each(function() {
    let mask = $(this).attr('data-mask');
    // let maxPhoneLength = parseInt($(this).attr('max-length-number'));

    Inputmask({ 'mask': mask, placeholder: '' }).mask(this);

    let $this = $(this),
      attr = $this.attr('data-valid');
    let target = $(`.js-phone-valid[data-valid="${attr}"]`);
    let maxPhoneLength = parseInt($(this).attr('max-length-number'));
    
    $(this).on('input', function() {
      let value = $(this).val();
        
      if (value.replace(/\D+/g, '').length >= maxPhoneLength) {
        target.removeAttr('disabled');
        target.removeClass('is-disabled');
      } else {
        target.prop('disabled', true);
        target.addClass('is-disabled');
      }
    });
  });

}
