import $ from 'jquery';

export function calendars() {

  let calendar = $('.js-calendar');

  calendar.each(function() {
    let event = $(this).find('.js-calendar-event');
    
    let myCalendar = createCalendar({
      data: {
        title: event.attr('data-title'),     
        start: new Date(event.attr('data-start-time')),   
        duration: parseInt(event.attr('data-duration')),                            
        end: new Date(event.attr('data-end-time')),    
        address: event.attr('data-address'),
        description: event.attr('data-description')
      }
    });
    this.appendChild(myCalendar);
  });  
}
