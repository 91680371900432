import $ from 'jquery';

export function viewportHeight() {
  function calcVH() {
    var vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    let el = document.querySelectorAll('.js-popup');    
    el = [...el];
    if(el) {
      for(let i=0; i<el.length; i++) {
        $(el[i]).css('min-height', vH + 'px');
      }
    }
  }
  calcVH();
  window.addEventListener('onorientationchange', calcVH, true);
  var scrollTimeout;
  var throttle = 200;
  $(window).on('resize', function() {
    if (!scrollTimeout) {
      scrollTimeout = setTimeout(function() {
        calcVH();
        scrollTimeout = null;
      }, throttle);
    }
  });

  
}
