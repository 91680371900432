import $ from 'jquery';
import { BODY, ISFIXED, ISHIDDEN } from '../_constants';
import { addBodyHidden, removeBodyHidden } from './bodyHiddenPosition.js';

export function popups() {

  let bodyHiddenStatus = 0;
  $('.js-popup-link').on('click', function(e) {
    e.preventDefault();

    let $this = $(this),
      attr = $this.attr('data-popup');
    let target = $(`.js-popup[data-popup="${attr}"]`);
    
    let isOpen = $('.js-popup').is(':visible');

    $('.js-popup').fadeOut(200);
    target.fadeIn(300);
    if(!isOpen) {
      addBodyHidden();  
    }
    
    if(target.attr('data-popup') === 'popup_thanks_msg') {
      setTimeout(() => {
        BODY.removeClass(ISHIDDEN);
        removeBodyHidden();
        target.fadeOut(400);
      }, 2500);
    }
    
  });

  $('.js-popup-overlay, .js-close-popup').on('click', function(e) {
    e.stopPropagation();
    
    let parent = $(this).parents('.js-popup');
    
    parent.fadeOut(200);

    if (bodyHiddenStatus === 1) {
      bodyHiddenStatus = 0;
    } else {
      BODY.removeClass(ISHIDDEN);
      removeBodyHidden();
      $('.js-header').removeClass(ISFIXED);
    };
    
  });

  $(document).keydown(function(e) {
    // ESCAPE key pressed
    if (e.keyCode === 27) {
      BODY.removeClass(ISHIDDEN);
      removeBodyHidden();
      $('.js-popup').fadeOut(200);
    }
  });

  $('.js-popup-content').on('click', function(e) {
    e.stopPropagation();
  });

};
